<template>
  <div>
    <h4 class="mainTitle">{{ title }}</h4>
    <div class="view pa24">
      <div class="registerTips">
        <div class="title">使用说明</div>
        <p>
          通过该接口创建的小程序创建成功后即为“已认证”状态，创建成功后，可直接绑定平台进行小程序的开发使用。
        </p>
        <p>1.填写以下信息，提交微信审核。</p>
        <p>
          2.法人微信收到消息模板，法人于24h内进行法人身份信息和人脸信息认证。
        </p>
        <p>3.身份认证通过后，小程序创建成功。</p>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" class="demo-ruleForm">
        <el-form-item label="企业名称" prop="companyName">
          <el-col :lg="11">
            <el-input placeholder="请输入企业名称" v-model="ruleForm.companyName"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="代码类型" prop="codeType">
          <el-col :lg="11">
            <el-select v-model="ruleForm.codeType" placeholder="请输入代码类型">
              <el-option :value="id" v-for="(name, id) in codeTypeData" :key="id" :label="name"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
        <el-form-item label="企业代码" prop="companyCode">
          <el-col :lg="11">
            <el-input placeholder="请输入企业代码" type="text" v-model="ruleForm.companyCode"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="法人微信号" prop="legalPersonaWechat">
          <el-col :lg="11">
            <el-input placeholder="请输入法人微信号" type="text" v-model="ruleForm.legalPersonaWechat">
            </el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="法人姓名" prop="legalPersonaName">
          <el-col :lg="11">
            <el-input placeholder="请输入法人姓名" type="text" v-model="ruleForm.legalPersonaName"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item>
          <el-col :lg="11">
            <el-button type="primary" @click="registerFun()" :loading="loading">立即注册</el-button>
          </el-col>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="错误提示" :visible.sync="dialogVisible" width="300px" >
      <div v-html="errorTips" style="color:#F56C6C;"></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  createApp
} from "@/api/authorization"
export default {
  data() {
    return {
      dialogVisible: false,
      errorTips: "",
      loading: false,
      title: "注册小程序", //页面标题
      //提交内容
      ruleForm: {
        type: "",
        codeType: "", //	企业代码类型（1：统一社会信用代码， 2：组织机构代码，3：营业执照注册号）
        companyCode: "", // 企业代码
        // companyAppId: "", // 企业id
        companyName: "", // 企业名
        legalPersonaName: "", // 法人姓名
        legalPersonaWechat: "", // 法人微信
      },
      codeTypeData: {
        1: "统一社会信用代码",
        2: "组织机构代码",
        3: "营业执照注册号",
      },
      //表单验证
      rules: {
        codeType: {
          required: true,
          message: "请选择企业代码类型",
          trigger: "change",
        }, //	企业代码类型（1：统一社会信用代码， 2：组织机构代码，3：营业执照注册号）
        companyCode: {
          required: true,
          message: "请输入企业代码",
          trigger: "blur",
        }, // 企业代码
        companyName: {
          required: true,
          message: "请输入企业名",
          trigger: "blur",
        }, // 企业名
        // componentPhone: {
        //   required: true,
        //   message: "请输入联系电话",
        //   trigger: "blur",
        // }, // 第三方联系电话
        legalPersonaName: {
          required: true,
          message: "请输入法人姓名",
          trigger: "blur",
        }, // 法人姓名
        legalPersonaWechat: {
          required: true,
          message: "请输入法人微信",
          trigger: "blur",
        }, // 法人微信
      },
    };
  },
  created() {
    let that = this;
    let type = that.$route.query.type;
    // that.ruleForm.type = type;
    // if (type === "user") {
    //   that.title = "注册用户端小程序";
    // } else if (type === "crm") {
    //   that.title = "注册CRM端小程序";
    // }
  },
  methods: {
    //立即注册
    registerFun() {
      let that = this;
      that.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          that.loading = true;
          createApp(this.ruleForm).then(res => {
            that.loading = false;
            if (res.errcode === 0) {
              that.$message({
                type: "success",
                message: "注册成功，小程序创建中...",
              });
              let timeFun = setTimeout(function () {
                that.$router.go(-1)
                clearTimeout(timeFun);
                timeFun = null;
              }, 2000)
            } else {
              that.errorTips = that.$store.state.gzhErrcode[res.errcode];
              that.dialogVisible = true;
            }
          }).catch(err=>{
            if(err.errcode===201){
              that.errorTips = that.$store.state.gzhErrcode[res.errcode];
              that.dialogVisible = true;
            }
          });

        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.mainTitle {
  font-size: 18px;
  padding: 20px 30px;
  background: #fff;
  border-bottom: 1px solid #eee;
}

.registerTips {
  background: #cdfffe;
  padding: 10px 20px;
  font-size: 12px;

  p {
    margin: 10px 5px;
  }

  .title {
    color: #51cdcb;
    font-size: 14px;
  }
}

.authorize {
  display: flex;
  background: #cdfffe;
  width: 300px;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px;
  border-radius: 10px;

  .wxapplyUrl {
    background: #fff;
    height: 50px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    .wxapplyImg {
      height: 30px;
      width: 30px;
    }
  }

  .el-button {
    height: 33px;
  }
}

table th {
  background: #f7fafa;
}

tbody:hover {
  background: #f7fafa;
}

table tr {
  height: 50px;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fixed-table {
  table-layout: fixed;
}

.textc {
  text-align: center;
}

.bgf6f9fa {
  background: #f6f9fa;
}

.table-bordered {
  border: 1px solid #dee2e6;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

body * {
  font-family: "Microsoft YaHei UI";
}

table {
  border-collapse: collapse;
}

* {
  outline: none;
}

*,
::after,
::before {
  box-sizing: border-box;
}
</style>